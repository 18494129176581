import Axios from 'axios';
import { Case } from '@/types/Case';
import { MOCK_GET_CASE } from '@/requests/Cases/getCase/getMockCase';
import axiosRetry from 'axios-retry';

axiosRetry(Axios, { retries: 4, retryDelay: axiosRetry.exponentialDelay });

export default async function getCase(id: number): Promise<Case> {
  if (process.env.NODE_ENV === 'development') {
    const caseData = MOCK_GET_CASE.find((caseItem) => caseItem.id === id);
    if (!caseData) {
      return MOCK_GET_CASE[0];
    }
    return caseData;
  }

  return (await Axios.post<Case>('/api/case/get/' + id)).data;
}
